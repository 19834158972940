export default [
    {
        path: '/product/categories',
        name: 'categories',
        props: true,
        component: () => import('@/views/product/categories/index.vue'),
        meta: {
            pageTitle: 'Categories',
            breadcrumb: [
                {
                text: 'Categories',
                active: true,
                },
            ],
        },
    },
    {
        path: '/product/categories/add',
        name: 'create-categories',
        component: () => import('@/views/product/categories/create.vue'),
        meta: {
            pageTitle: 'Create Categories',
            breadcrumb: [
                {
                text: 'Categories',
                to: { name: 'categories' },
                },
                {
                text: 'Create Categories',
                active: true,
                },
            ],
        },
    },
    {
        path: '/product/categories/:id',
        name: 'detail-categories',
        props: true,
        component: () => import('@/views/product/categories/detail.vue'),
        meta: {
            pageTitle: 'Detail Categories',
            breadcrumb: [
                {
                text: 'Categories',
                to: { name: 'categories' },
                },
                {
                text: 'Detail Categories',
                active: true,
                },
            ],
        },
    },
]