export default [
    {
        path: '/product/units',
        name: 'units',
        props: true,
        component: () => import('@/views/product/units/index.vue'),
        meta: {
            pageTitle: 'Units',
            breadcrumb: [
                {
                text: 'Units',
                active: true,
                },
            ],
        },
    },
    {
        path: '/product/units/add',
        name: 'create-units',
        component: () => import('@/views/product/units/create.vue'),
        meta: {
            pageTitle: 'Create Units',
            breadcrumb: [
                {
                text: 'Units',
                to: { name: 'units' },
                },
                {
                text: 'Create Units',
                active: true,
                },
            ],
        },
    },
    {
        path: '/product/units/:id',
        name: 'detail-units',
        props: true,
        component: () => import('@/views/product/units/detail.vue'),
        meta: {
            pageTitle: 'Detail Units',
            breadcrumb: [
                {
                text: 'Units',
                to: { name: 'units' },
                },
                {
                text: 'Detail Units',
                active: true,
                },
            ],
        },
    },
]