export default [
    {
        path: '/users',
        name: 'users',
        props: true,
        component: () => import('@/views/users/index.vue'),
        meta: {
            pageTitle: 'Users',
            breadcrumb: [
                {
                text: 'Users',
                active: true,
                },
            ],
        },
    },
    {
        path: '/users/add',
        name: 'create-users',
        component: () => import('@/views/users/create.vue'),
        meta: {
            pageTitle: 'Create User',
            breadcrumb: [
                {
                text: 'Users',
                to: { name: 'users' },
                },
                {
                text: 'Create User',
                active: true,
                },
            ],
        },
    },
    {
        path: '/users/:id',
        name: 'detail-users',
        props: true,
        component: () => import('@/views/users/detail.vue'),
        meta: {
            pageTitle: 'Detail User',
            breadcrumb: [
                {
                text: 'Users',
                to: { name: 'users' },
                },
                {
                text: 'Detail User',
                active: true,
                },
            ],
        },
    },
]