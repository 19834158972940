export default [
    {
        path: '/sales/catalog',
        name: 'catalog',
        props: true,
        component: () => import('@/views/sales/catalog/index.vue'),
        meta: {
            pageTitle: 'Catalog',
            breadcrumb: [
                {
                text: 'Catalog',
                active: true,
                },
            ],
        },
    },
    {
        path: '/sales/catalog/add',
        name: 'create-catalog',
        component: () => import('@/views/sales/catalog/create.vue'),
        meta: {
            pageTitle: 'Create Catalog',
            breadcrumb: [
                {
                text: 'Catalog',
                to: { name: 'catalog' },
                },
                {
                text: 'Create Catalog',
                active: true,
                },
            ],
        },
    },
    {
        path: '/sales/catalog/:id',
        name: 'detail-catalog',
        props: true,
        component: () => import('@/views/sales/catalog/detail.vue'),
        meta: {
            pageTitle: 'Detail Catalog',
            breadcrumb: [
                {
                text: 'Catalog',
                to: { name: 'catalog' },
                },
                {
                text: 'Detail Catalog',
                active: true,
                },
            ],
        },
    },
]