export default [
    {
        path: '/warehouse/warehouse-location',
        name: 'warehouse-location',
        props: true,
        component: () => import('@/views/warehouse/warehouse-location/index.vue'),
        meta: {
            pageTitle: 'Warehouses',
            breadcrumb: [
                {
                text: 'Warehouses',
                active: true,
                },
            ],
        },
    },
    {
        path: '/warehouse/warehouse-location/add',
        name: 'create-warehouse-location',
        component: () => import('@/views/warehouse/warehouse-location/create.vue'),
        meta: {
            pageTitle: 'Create Warehouse',
            breadcrumb: [
                {
                text: 'Warehouses',
                to: { name: 'warehouse-location' },
                },
                {
                text: 'Create Warehouse',
                active: true,
                },
            ],
        },
    },
    {
        path: '/warehouse/warehouse-location/:id',
        name: 'detail-warehouse-location',
        props: true,
        component: () => import('@/views/warehouse/warehouse-location/detail.vue'),
        meta: {
            pageTitle: 'Detail Warehouse',
            breadcrumb: [
                {
                text: 'Warehouses',
                to: { name: 'warehouse-location' },
                },
                {
                text: 'Detail Warehouse',
                active: true,
                },
            ],
        },
    },
]