export default [
    {
        path: '/product/vendor',
        name: 'vendor',
        props: true,
        component: () => import('@/views/product/vendor/index.vue'),
        meta: {
            pageTitle: 'Vendor',
            breadcrumb: [
                {
                text: 'Vendor',
                active: true,
                },
            ],
        },
    },
    {
        path: '/product/vendor/add',
        name: 'create-vendor',
        component: () => import('@/views/product/vendor/create.vue'),
        meta: {
            pageTitle: 'Create Vendor',
            breadcrumb: [
                {
                text: 'Vendor',
                to: { name: 'vendor' },
                },
                {
                text: 'Create Vendor',
                active: true,
                },
            ],
        },
    },
    {
        path: '/product/vendor/:id',
        name: 'detail-vendor',
        props: true,
        component: () => import('@/views/product/vendor/detail.vue'),
        meta: {
            pageTitle: 'Detail Vendor',
            breadcrumb: [
                {
                text: 'Vendor',
                to: { name: 'vendor' },
                },
                {
                text: 'Detail Vendor',
                active: true,
                },
            ],
        },
    },
]