export default [
    {
        path: '/product/vendor-category',
        name: 'vendor-category',
        props: true,
        component: () => import('@/views/product/vendor-category/index.vue'),
        meta: {
            pageTitle: 'Vendor Category',
            breadcrumb: [
                {
                text: 'Vendor Category',
                active: true,
                },
            ],
        },
    },
    {
        path: '/product/vendor-category/add',
        name: 'create-vendor-category',
        component: () => import('@/views/product/vendor-category/create.vue'),
        meta: {
            pageTitle: 'Create Vendor Category',
            breadcrumb: [
                {
                text: 'Vendor Category',
                to: { name: 'vendor-category' },
                },
                {
                text: 'Create Vendor Category',
                active: true,
                },
            ],
        },
    },
    {
        path: '/product/vendor-category/:id',
        name: 'detail-vendor-category',
        props: true,
        component: () => import('@/views/product/vendor-category/detail.vue'),
        meta: {
            pageTitle: 'Detail Vendor Category',
            breadcrumb: [
                {
                text: 'Vendor Category',
                to: { name: 'vendor-category' },
                },
                {
                text: 'Detail Vendor Category',
                active: true,
                },
            ],
        },
    },
]