export default [
    {
        path: '/sales/order',
        name: 'order',
        props: true,
        component: () => import('@/views/sales/order/index.vue'),
        meta: {
            pageTitle: 'Order',
            breadcrumb: [
                {
                text: 'Order',
                active: true,
                },
            ],
        },
    },
    {
        path: '/sales/order/:id',
        name: 'detail-order',
        props: true,
        component: () => import('@/views/sales/order/detail.vue'),
        meta: {
            pageTitle: 'Detail Order',
            breadcrumb: [
                {
                text: 'Order',
                to: { name: 'order' },
                },
                {
                text: 'Detail Order',
                active: true,
                },
            ],
        },
    },
]