export default [
    {
        path: '/warehouse/item',
        name: 'item',
        props: true,
        component: () => import('@/views/warehouse/item/index.vue'),
        meta: {
            pageTitle: 'Item',
            breadcrumb: [
                {
                text: 'Item',
                active: true,
                },
            ],
        },
    },
    {
        path: '/warehouse/item/add',
        name: 'create-item',
        component: () => import('@/views/warehouse/item/create.vue'),
        meta: {
            pageTitle: 'Create Item',
            breadcrumb: [
                {
                text: 'Item',
                to: { name: 'item' },
                },
                {
                text: 'Create Item',
                active: true,
                },
            ],
        },
    },
    {
        path: '/warehouse/item/:id',
        name: 'detail-item',
        props: true,
        component: () => import('@/views/warehouse/item/detail.vue'),
        meta: {
            pageTitle: 'Detail Item',
            breadcrumb: [
                {
                text: 'Item',
                to: { name: 'item' },
                },
                {
                text: 'Detail Item',
                active: true,
                },
            ],
        },
    },
]