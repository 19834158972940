export default [
    {
        path: '/warehouse/stock-opname',
        name: 'stock-opname',
        props: true,
        component: () => import('@/views/warehouse/stock-opname/index.vue'),
        meta: {
            pageTitle: 'Stock Opname',
            breadcrumb: [
                {
                text: 'Stock Opname',
                active: true,
                },
            ],
        },
    },
    {
        path: '/warehouse/stock-opname/add',
        name: 'create-stock-opname',
        component: () => import('@/views/warehouse/stock-opname/create.vue'),
        meta: {
            pageTitle: 'Create Stock Opname',
            breadcrumb: [
                {
                text: 'Stock Opname',
                to: { name: 'stock-opname' },
                },
                {
                text: 'Create Stock Opname',
                active: true,
                },
            ],
        },
    },
    {
        path: '/warehouse/stock-opname/:id',
        name: 'detail-stock-opname',
        props: true,
        component: () => import('@/views/warehouse/stock-opname/detail.vue'),
        meta: {
            pageTitle: 'Detail Stock Opname',
            breadcrumb: [
                {
                text: 'Stock Opname',
                to: { name: 'stock-opname' },
                },
                {
                text: 'Detail Stock Opname',
                active: true,
                },
            ],
        },
    },
]