export default [
    {
        path: '/company',
        name: 'company',
        props: true,
        component: () => import('@/views/company/index.vue'),
        meta: {
            pageTitle: 'Company',
            breadcrumb: [
                {
                text: 'Company',
                active: true,
                },
            ],
        },
    },
]