export default [
    {
        path: '/master/payment-type',
        name: 'payment-type',
        props: true,
        component: () => import('@/views/master/payment-type/index.vue'),
        meta: {
            pageTitle: 'Payment Type',
            breadcrumb: [
                {
                text: 'Payment Type',
                active: true,
                },
            ],
        },
    },
    {
        path: '/master/payment-type/add',
        name: 'create-payment-type',
        component: () => import('@/views/master/payment-type/create.vue'),
        meta: {
            pageTitle: 'Create Payment Type',
            breadcrumb: [
                {
                text: 'Payment Type',
                to: { name: 'payment-type' },
                },
                {
                text: 'Create Payment Type',
                active: true,
                },
            ],
        },
    },
    {
        path: '/master/payment-type/:id',
        name: 'detail-payment-type',
        props: true,
        component: () => import('@/views/master/payment-type/detail.vue'),
        meta: {
            pageTitle: 'Detail Payment Type',
            breadcrumb: [
                {
                text: 'Payment Type',
                to: { name: 'payment-type' },
                },
                {
                text: 'Detail Payment Type',
                active: true,
                },
            ],
        },
    },
]