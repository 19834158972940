export default [
    {
        path: '/product/products',
        name: 'products',
        props: true,
        component: () => import('@/views/product/products/index.vue'),
        meta: {
            pageTitle: 'Products',
            breadcrumb: [
                {
                text: 'Products',
                active: true,
                },
            ],
        },
    },
    {
        path: '/product/products/add',
        name: 'create-products',
        component: () => import('@/views/product/products/create.vue'),
        meta: {
            pageTitle: 'Create Products',
            breadcrumb: [
                {
                text: 'Products',
                to: { name: 'products' },
                },
                {
                text: 'Create Products',
                active: true,
                },
            ],
        },
    },
    {
        path: '/product/products/:id',
        name: 'detail-products',
        props: true,
        component: () => import('@/views/product/products/detail.vue'),
        meta: {
            pageTitle: 'Detail Products',
            breadcrumb: [
                {
                text: 'Products',
                to: { name: 'products' },
                },
                {
                text: 'Detail Products',
                active: true,
                },
            ],
        },
    },
]