import { password } from '@/@core/utils/validations/validations'
import Vue from 'vue'
import VueRouter from 'vue-router'
import {createRouter,createWebHistory} from 'vue-router'
import dashboard from './routes/dashboard'
import login from './routes/login'
import profile from './routes/profile'
import forgotPassword from './routes/forgotPassword'
import organizations from './routes/organizations'
import users from './routes/users'
import company from './routes/company'
import units from './routes/units'
import categories from './routes/categories'
import products from './routes/products'
import vendorCategory from './routes/vendorCategory'
import vendor from './routes/vendor'
import paymentType from './routes/paymentType'
import order from './routes/order'
import warehouseLocation from './routes/warehouseLocation'
import item from './routes/item'
import catalog from './routes/catalog'
import stockOpname from './routes/stockOpname'
import error404 from './routes/error404'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes: [
    ...dashboard,
    ...login,
    ...profile,
    ...forgotPassword,
    ...organizations,
    ...users,
    ...company,
    ...units,
    ...categories,
    ...products,
    ...vendorCategory,
    ...vendor,
    ...paymentType,
    ...order,
    ...warehouseLocation,
    ...item,
    ...catalog,
    ...stockOpname,
    ...error404,
  ],
})

// ? For splash screen
// Remove afterEach hook if you are not using splash screen
// router.afterEach(() => {
//   // Remove initial loading
//   const appLoading = document.getElementById('loading-bg')
//   if (appLoading) {
//     appLoading.style.display = 'none'
//   }
// })

router.beforeEach((to, from, next) => {
  const appLoading = document.getElementById('loading-bg')
  if (appLoading) {
    appLoading.style.display = 'none'
  }
  
  const isAuthenticated = JSON.parse(sessionStorage.getItem('authenticated'));
  if (to.name !== 'login' && to.name !== 'forgot-password' && !isAuthenticated) next({ path: '/login' });
  // else if (to.name !== 'login' && !isAuthenticated) next({ path: '/' });
  else next();
});

export default router
